<template>
  <section class="email-not-verified">
    <div class="w-full">
      <div class="text-n-4xl font-bold">
        {{$t('email.confirm_email')}}
      </div>
    
      <div class="text-n-md font-semibold mt-n-4xl">
        {{$t('email.looks_like_you_havent')}}
      </div>

      <div class="text-n-md font-semibold mt-n-2xl">
        {{$t('email.check_your_inbox')}}
      </div>

      <div class="text-n-md font-semibold mt-n-2xl">
        {{$t('email.please_be_sure')}}
      </div>
    </div>

    <div class="w-full mt-n-5xl">
      <ButtonV2
        @onClick="hideModal"
        :inactive="isLoading"
        testId="btn-close"
        :label="$t('navigation.close')"
        wide
      />
      <ButtonV2
        @onClick="resendConfirmationEmail"
        :inactive="isLoading"
        :label="$t('email.resend_email')"
        testId="btn-resend-email"
        class="mt-n-2xl"
        version="secondary"
        wide
      />
    </div>
  </section>
</template>

<script>
  import ButtonV2 from '@/stories/misc/ButtonV2';
  import RESEND_EMAIL_CONFIRMATION from '@/graphql/mutations/ResendEmailConfirmation.gql';

  export default {
    name: 'EmailNotVerified',
    components: {
      ButtonV2,
    },

    data() {
      return {
        isLoading: false,
      };
    },

    async mounted() {
      await this.$store.dispatch('events/track', {
        event: 'EMAIL_CONFIRMATION_VIEWED',
      });
    },

    methods: {
      async resendConfirmationEmail() {
        try {
          this.isLoading = true;

          await this.apolloApiCall({
            mutation: RESEND_EMAIL_CONFIRMATION,
          });

          await this.$store.dispatch('events/track', {
            event: 'EMAIL_CONFIRMATION_SENT',
          });

          this.showSuccess(this.$t('success.confirmation_email_sent'));

          this.hideModal();
        } catch (err) {
          await this.showError(err);
        } finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style scoped>
  .email-not-verified {
    @apply flex flex-col justify-between;
    min-height: 400px;
  }
</style>
